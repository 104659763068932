<template>
    <div>
        <div style="background-color:#deecda;width:100%;height:200px">
        </div>
        <v-row justify="center" style="margin-top:-100px;">
            <v-col lg="7" md="10" cols="12">
                <v-card tile class="px-5 py-8">
                    <v-row align="center">
                        <v-col cols="3" class="text-center">
                            <v-img
                                contain
                                :max-height="100"
                                src="/static/market-logo.png">
                            </v-img>
                        </v-col>

                        <v-col cols="9">
                            <div class="text-h4">Tutti Market</div>
                            <v-combobox
                                hide-details
                                dense
                                class="mt-4"
                                v-model="marketHost"
                                label="Select or Specify Market Host..."
                                :items="marketHosts"
                                />
                            <v-row class="mt-4">
                                <v-btn
                                    outlined
                                    :disabled="marketHost==''"
                                    class="ma-2"
                                    color="indigo"
                                    :href="marketHost"
                                    target="_blank">
                                    <v-icon>mdi-web</v-icon>Website
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>

                <credentials-card
                    class="my-3"
                    :duct="duct"
                    :user="user"
                    :market="market"
                />
                <page-cards
                    class="my-3"
                />
            </v-col>
        </v-row>

    </div>
</template>
<script>
import PageCards from './TopPageCards'
import CredentialsCard from './TopCredentialsCard'

export default {
    components: {
        PageCards,
        CredentialsCard,
    },
    data: () => ({
        marketHost: '',
        marketHosts: [
            'http://localhost:18888',
            'http://192.168.11.27:8080',
            'https://teai.tutti.market'
        ],

    }),
    props: ['duct', 'market', 'user', 'projectName'],
    watch: {
        async marketHost(host) {
            window.localStorage.setItem('tuttiMarketHost', host);
            if(host){ this.$root.$emit('update-host', host); }
        }
    },
    mounted() {
        const marketHost = window.localStorage.getItem('tuttiMarketHost');
        if(marketHost) this.marketHost = marketHost;
    }
}
</script>

