var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", {
        staticStyle: {
          "background-color": "#deecda",
          width: "100%",
          height: "200px",
        },
      }),
      _c(
        "v-row",
        {
          staticStyle: { "margin-top": "-100px" },
          attrs: { justify: "center" },
        },
        [
          _c(
            "v-col",
            { attrs: { lg: "7", md: "10", cols: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "px-5 py-8", attrs: { tile: "" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-center", attrs: { cols: "3" } },
                        [
                          _c("v-img", {
                            attrs: {
                              contain: "",
                              "max-height": 100,
                              src: "/static/market-logo.png",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "9" } },
                        [
                          _c("div", { staticClass: "text-h4" }, [
                            _vm._v("Tutti Market"),
                          ]),
                          _c("v-combobox", {
                            staticClass: "mt-4",
                            attrs: {
                              "hide-details": "",
                              dense: "",
                              label: "Select or Specify Market Host...",
                              items: _vm.marketHosts,
                            },
                            model: {
                              value: _vm.marketHost,
                              callback: function ($$v) {
                                _vm.marketHost = $$v
                              },
                              expression: "marketHost",
                            },
                          }),
                          _c(
                            "v-row",
                            { staticClass: "mt-4" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-2",
                                  attrs: {
                                    outlined: "",
                                    disabled: _vm.marketHost == "",
                                    color: "indigo",
                                    href: _vm.marketHost,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("mdi-web")]),
                                  _vm._v("Website "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("credentials-card", {
                staticClass: "my-3",
                attrs: { duct: _vm.duct, user: _vm.user, market: _vm.market },
              }),
              _c("page-cards", { staticClass: "my-3" }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }