var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { tile: "" } },
    [
      _c(
        "v-list",
        _vm._l(_vm.listItems, function (item) {
          return _c(
            "v-list-item",
            { key: item.to, attrs: { to: item.to } },
            [
              _c(
                "v-list-item-avatar",
                [
                  _c("v-icon", { staticClass: "grey lighten-2" }, [
                    _vm._v(_vm._s(item.icon)),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", {
                    domProps: { textContent: _vm._s(item.title) },
                  }),
                  _c("v-list-item-subtitle", {
                    domProps: { textContent: _vm._s(item.text) },
                  }),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }