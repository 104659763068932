<template>
    <v-card tile>
        <div v-if="signedIn">
            <v-card-text>
                User ID: {{ user.id }}<br>
                Last signed in at: {{ dateFormat(new Date(user.accountInfo.last_signed_in_at), 'yyyy-mm-dd HH:MM:ss') }}
            </v-card-text>

            <v-card-text class="pt-0">
                <v-btn
                    outlined
                    class="ml-3"
                    color="grey"
                    @click="signOut()">
                    Logout
                </v-btn>
            </v-card-text>
        </div>

        <div v-else>
            <v-card-text>
                You are not signed into Tutti Market.
                <v-btn
                    text
                    color="indigo"
                    @click="$refs.dialogSignIn.shown = true;">
                Set credentials
            </v-btn>
            </v-card-text>
        </div>

        <dialog-sign-in
            ref="dialogSignIn"
            :market="market"
            />
    </v-card>
</template>

<script>
import DialogSignIn from './DialogSignIn'
import dateFormat from 'dateformat'

export default {
    components: {
        DialogSignIn,
    },
    data: () => ({
        dateFormat,
    }),
    props: ['duct', 'user', 'market'],
    computed: {
        signedIn() {
            return this.user.id.length>0 && this.user.accountInfo;
        },
    },
    methods: {
        async signOut() {
            await this.market.signOut();
            this.$root.$emit('sign-out');
        },
    },
}
</script>
