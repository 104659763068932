var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { tile: "" } },
    [
      _vm.signedIn
        ? _c(
            "div",
            [
              _c("v-card-text", [
                _vm._v(" User ID: " + _vm._s(_vm.user.id)),
                _c("br"),
                _vm._v(
                  " Last signed in at: " +
                    _vm._s(
                      _vm.dateFormat(
                        new Date(_vm.user.accountInfo.last_signed_in_at),
                        "yyyy-mm-dd HH:MM:ss"
                      )
                    ) +
                    " "
                ),
              ]),
              _c(
                "v-card-text",
                { staticClass: "pt-0" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-3",
                      attrs: { outlined: "", color: "grey" },
                      on: {
                        click: function ($event) {
                          return _vm.signOut()
                        },
                      },
                    },
                    [_vm._v(" Logout ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "v-card-text",
                [
                  _vm._v(" You are not signed into Tutti Market. "),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "indigo" },
                      on: {
                        click: function ($event) {
                          _vm.$refs.dialogSignIn.shown = true
                        },
                      },
                    },
                    [_vm._v(" Set credentials ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _c("dialog-sign-in", {
        ref: "dialogSignIn",
        attrs: { market: _vm.market },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }