<template>
    <v-card tile>
        <v-list>
            <v-list-item
                v-for="item in listItems"
                :key="item.to"
                :to="item.to"
            >
                <v-list-item-avatar>
                    <v-icon class="grey lighten-2">{{ item.icon }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title v-text="item.title" />
                    <v-list-item-subtitle v-text="item.text" />
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>
export default {
    data: () => ({
        listItems: [
            {
                icon: 'mdi-cog-outline',
                title: 'Parameter Sets',
                text: 'Registered parameters for creating jobs',
                to: 'parameter-set'
            },
            {
                icon: "mdi-wallet-travel",
                title: "Jobs",
                text: "Create, customize, or remove task batches.",
                to: 'job'
            },
            //{
            //    imgSrc: "https://assets.st-note.com/production/uploads/images/30971758/rectangle_large_type_2_d321a7db4258595199bc6a264e7b320b.jpg?fit=bounds&format=jpeg&quality=45&width=960",
            //    title: "Qualifications",
            //    text: "Create or remove qualifications.",
            //    actionLabel: "Manage qualifications",
            //    to: "qual"
            //},
            //{
            //    imgSrc: "https://jopus.net/wp-content/uploads/2018/10/shutterstock_1065989561.jpg",
            //    title: "Assignments",
            //    text: "See and approve/deny assignments.",
            //    actionLabel: "Manage assignments",
            //    to: "assignment"
            //},
        ]
    }),
    methods: {
        displayWidth() {
            console.log(this.$vuetify.breakpoint.sm);
        }
    }
}
</script>
