<template>
    <v-dialog v-model="shown" max-width="600">
        <v-card>
            <v-card-title>Sign In</v-card-title>
            <v-card-text>
                <v-text-field
                    @keydown.enter="signIn()"
                    v-model="userId"
                    label="User Name"
                    />
                <v-text-field
                    @keydown.enter="signIn()"
                    v-model="password"
                    type="password"
                    label="Password"
                    />
                <v-alert v-if="errorText!=''" dense type="error">{{ errorText }}</v-alert>
            </v-card-text>
            <tutti-dialog-actions
                :actions="[
                    {
                        label: 'Cancel',
                        callback: () => { shown = false; },
                    },
                    {
                        label: 'Sign in',
                        color: 'indigo darken-2',
                        callback: signIn,
                        disabled: userId=='' || password==''
                    },
                ]"
                />
        </v-card>
    </v-dialog>
</template>
<script>
import TuttiDialogActions from '@/components/ui/TuttiDialogActions'
import { TuttiMarketDuctError } from '@/lib/market/ducts.js'

export default {
    components: {
        TuttiDialogActions,
    },
    data: () => ({
        shown: false,
        userId: '',
        password: '',
        errorText: '',
    }),
    props: ['market'],
    methods: {
        async signIn() {
            try {
                if(await this.market.signIn(this.userId, this.password)){
                    if(!await this.market.isUserRequestable(this.userId)){
                        await this.market.signOut();
                        throw Error('not-workable');
                    } else {
                        this.$root.$emit('sign-in');
                        this.shown = false;
                    }
                } else {
                    this.errorText = 'Invalid user ID or password';
                }
            } catch (e) {
                if(e instanceof TuttiMarketDuctError) {
                    console.log(e);
                } else {
                    this.errorText = 'Not a requester account';
                }
            }
        },
    },
    watch: {
        shown() {
            this.userId = '';
            this.password = '';
            this.errorText = '';
        },
        userId() { this.errorText = ''; },
        password() { this.errorText = ''; },
    }
}
</script>
